:root {
  --world: translateZ(-100px) rotateX(0) rotateY(0);
}

#world {
  transform: var(--world);
}

[data-theme="dark"] #world {
  mix-blend-mode: overlay;
}

body {
  overflow-x: hidden;
}

#viewport {
  perspective: 400px;
  position: absolute;
  inset: 0;
  overflow: hidden;
  background: var(--color__bg);
}
