// -------------------------------------------------------------
// Reset
// -------------------------------------------------------------

// Box sizing reset
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

// -------------------------------------------------------------
// Fonts
// -------------------------------------------------------------

/**
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Luke Variable Font by The Northern Block
 * URL: https://www.myfonts.com/products/variable-font-luke-214331
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * © 2023 MyFonts Inc.
 */

@font-face {
  font-family: "LukeVariableFont";
  src: url("../../../src/fonts/LukeVariableFont.woff2") format("woff2");
  src: url("../../../src/fonts/LukeVariableFont.woff") format("woff");
}

@font-face {
  font-family: "Neco";
  src: url("../../../src/fonts/Neco-Variable.ttf") format("truetype-variations");
}

@font-face {
  font-family: "iaWriter";
  src: url("../../../src/fonts/iAWriterQuattroV.ttf")
    format("truetype-variations");
}

// -------------------------------------------------------------
// Body, General
// -------------------------------------------------------------

body,
html {
  font-family: "PxGroteskRegular";
  margin: 0;
  width: 100vw;
  background-color: $color-bg__light;
  font-size: $font-size__base;

  // Content wrapper
  .wrapper {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__post {
      background-color: var(--color__bg);
      color: var(--color);
      transition: background-color $transition-duration
          $transition-timing-function $transition-delay,
        color $transition-duration $transition-timing-function $transition-delay;
    }

    &__home {
      min-height: 100vh;
      height: auto;
      position: relative;
      color: var(--color);
      align-content: space-between;
    }

    .cloud-background {
      inset: 0;
      opacity: var(--clouds);
      position: absolute;
      background-color: $color-bg__dark;
    }
  }
}

// Typography
.section-blood-count,
.section-weather-count {
  z-index: z(blood-count);
  margin-top: $space__base * 2;
  display: flex;
  width: auto;
  justify-content: center;
}

.blood-count {
  &__counts,
  &__desc {
    grid-template-columns: repeat(5, 1fr);
  }
}

.weather-count {
  &__counts,
  &__desc {
    grid-template-columns: repeat(3, 1fr);
  }
}

.blood-count,
.weather-count {
  color: $color__blood;
  display: grid;
  grid-template-rows: repeat(2, auto);
  gap: 10px;

  &__counts,
  &__desc {
    display: grid;
    span {
      justify-self: center;
      margin-right: $space__base;
      margin-left: $space__base;
    }
  }
  &__desc {
    @include font-family-neco;
    font-variation-settings: "wght" 700;
    text-transform: uppercase;
    letter-spacing: 0.25em;
    font-size: 1.25rem;
    span {
      padding-right: 0;
      writing-mode: vertical-lr;
    }
  }
}

// Layer for background image
.p5-sketch {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top center;
  position: absolute;
  z-index: z(background-layer);
  top: 0;
  right: 0;
  width: 100%;
  height: 800px;
}

iframe {
  display: none;
}

// -------------------------------------------------------------
// Header / Navigation
// -------------------------------------------------------------

// Circle
.logo {
  @include button-reset;
  z-index: z(btn-circle);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &__link:hover textpath {
    fill: $color__accent;
  }

  &.logo-position {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &--top {
      top: $space__base * 2;
      transform: translate(-50%, 0);
    }
  }

  &.drill .circle {
    animation-duration: 1s;
  }

  &.rotateBack .circle {
    animation-name: rotateBack;
  }

  &.rotateForward .circle {
    animation-name: rotateForward;
  }
}

.placeholder-logo {
  margin: $space__base * 2;
  height: 70vh;
  width: 70vh;
  max-width: calc(100vw - #{$space__base} * 8);
  background: transparent;
}

.circle {
  fill: var(--color);
  transition: fill $transition-duration $transition-timing-function
      $transition-delay,
    height $transition-duration $transition-timing-function,
    max-width $transition-duration $transition-timing-function;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  &.logo-size {
    height: 70vh;
    max-width: calc(100vw - #{$space__base} * 8);
    animation-duration: 20s;

    &--small {
      height: 150px;
      max-width: 150px;
      animation-duration: 10s;
    }
  }

  textPath {
    @include font-family-luke;
    font-size: 4rem;
    letter-spacing: 1rem;
  }
}

.wrapper__home .circle {
  transition: none;
}

// Navigation: Date and buttons next / previous
.navigation {
  z-index: z(navigation);
  position: relative;
  width: calc(100% - (#{$space__base} * 4));
  text-align: center;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;

  &-post {
    margin: ($space__base * 12) ($space__base * 2) 0 ($space__base * 2);
  }

  &-home {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 800px) {
    display: block;
  }
}

// Counter
.counter {
  @include time-format;
  white-space: nowrap;
  padding: 0 $space__base 0 $space__base;
  font-size: clamp(2.5rem, 7vw, 6rem);
  font-weight: 400;
  margin: -$space__base 0 0 0;
  display: block;
  flex-grow: 0;
}

.header-home {
  top: $space__base * 12;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: z(header-home);
}

// Button direction
.link-direction {
  text-decoration: none;
  display: flex;
  flex-grow: 1;
}

.btn-direction {
  @include button-reset;
  height: 50px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  z-index: z(btn-direction);
  overflow: hidden;
  gap: 3px;
  position: relative;

  &.notAllowed {
    cursor: not-allowed;
  }

  &__text {
    color: $color__accent;
    text-transform: uppercase;
    z-index: z(btn-direction-text);
    flex-grow: 0;
  }

  &__line {
    display: block;
    background: $color__accent;
    height: 2px;
    margin-top: 4px;
    z-index: z(btn-direction-line);
    transition: $transition__btn-direction;

    &__right__back {
      flex-grow: 1;
    }

    &__left__back {
      flex-grow: 0;
    }

    &__right__forward {
      flex-grow: 0;
    }

    &__left__forward {
      flex-grow: 1;
    }

    &__lineThrough__forward {
      position: absolute;
      top: calc(50% - 3px);
      right: 0;
      width: 0;
      transition: $transition__btn-direction;

      &.lineThrough {
        width: 100%;
      }
    }

    &__lineThrough__back {
      position: absolute;
      top: calc(50% - 3px);
      left: 0;
      width: 0;
      transition: $transition__btn-direction;

      &.lineThrough {
        width: 100%;
      }
    }
  }

  &:hover .btn-direction__line {
    &__right__back {
      flex-grow: 0;

      &.lineThrough {
        flex-grow: 1;
      }
    }

    &__left__back {
      flex-grow: 1;

      &.lineThrough {
        flex-grow: 0;
      }
    }

    &__right__forward {
      flex-grow: 1;

      &.lineThrough {
        flex-grow: 0;
      }
    }

    &__left__forward {
      flex-grow: 0;

      &.lineThrough {
        flex-grow: 1;
      }
    }
  }
}

// Quicklinks
.postdatatime {
  position: fixed;
  padding: ($space__base * 2) ($space__base * 2) 0 ($space__base * 2);
  width: 100%;
  display: flex;
  justify-content: center;
  gap: $space__base * 2;
  z-index: z(quicklinks);
  color: var(--color);
  transition: color $transition-duration $transition-timing-function
    $transition-delay;

  > span {
    @include font-family-luke;
  }

  .btn-postdatatime {
    @include button-reset;
    @include time-format;
    white-space: nowrap;
    overflow-x: hidden;

    &:hover {
      color: $color__accent;
    }
  }

  &__back,
  &__forward {
    display: flex;
    gap: $space__base * 2;
  }
  &__forward {
    right: $space__base * 2;
  }
  &__back {
    left: $space__base * 2;
  }
}

// -------------------------------------------------------------
// Content
// -------------------------------------------------------------

// Posts content
.postContent {
  container-type: inline-size;
  width: calc(100% - #{$space__base} * 4);
  margin: 0 auto 0 auto;
  opacity: 0;
  z-index: z(post-content);
  @include font-family-neco;
  margin-top: 4 * $space__base;

  p {
    font-size: 5cqi;
    line-height: 1.2em;
    overflow: hidden;
  }

  h2 {
    font-size: clamp(1rem, 2vw, 3rem);
    font-family: "PxGroteskRegular";
    font-weight: 400;
  }

  .feeling_wrapper {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .feeling--hidden {
      height: 0;
      visibility: hidden;
      overflow: hidden;
      user-select: none;
      pointer-events: none;
      font-variation-settings: "wght" 800;
    }
  }

  .feeling {
    animation: pulse1 2s ease-in-out infinite;
    font-variation-settings: "wght" 900;
    letter-spacing: 0;
    position: relative;

    &-letter-start {
      animation: pulse3 2s ease-in-out infinite;
      font-variation-settings: "wght" 500;

      + span {
        animation: pulse2 2s ease-in-out infinite;
        font-variation-settings: "wght" 700;
      }
    }

    &-letter-end {
      animation: pulse2 2s ease-in-out infinite;
      font-variation-settings: "wght" 700;

      + span {
        animation: pulse3 2s ease-in-out infinite;
        font-variation-settings: "wght" 500;
      }
    }
  }

  @keyframes pulse1 {
    0% {
      font-variation-settings: "wght" 400;
    }
    50% {
      font-variation-settings: "wght" 900;
      letter-spacing: -0.01em;
    }
    100% {
      font-variation-settings: "wght" 400;
    }
  }
  @keyframes pulse2 {
    0% {
      font-variation-settings: "wght" 400;
    }
    50% {
      font-variation-settings: "wght" 700;
    }
    100% {
      font-variation-settings: "wght" 400;
    }
  }
  @keyframes pulse3 {
    0% {
      font-variation-settings: "wght" 400;
    }
    50% {
      font-variation-settings: "wght" 500;
    }
    100% {
      font-variation-settings: "wght" 400;
    }
  }

  &.visible {
    animation: fadeInFromNone 1s linear;
    opacity: 1;
  }
}

//animation: breathe 2000ms infinite forwards;
@keyframes breathe {
  0% {
    font-variation-settings: "wght" 700;
  }

  50% {
    font-variation-settings: "wght" 100;
  }

  100% {
    font-variation-settings: "wght" 700;
  }
}
