// Width
$content-width: 1000px;

// Colors
$color__light: #ffffff;
$color__dark: #000000;
$color__accent: #ff0080;
$text-color: $color__dark;
$color__blood: #02788e;

$mixed-color__blood-dark: mix($color__blood, #000, 40);
$mixed-color__blood-darker: mix($color__blood, #000, 70);
$mixed-color__blood-light: mix($color__blood, #fff, 40);
$mixed-color__blood-lighter: mix($color__blood, #fff, 70);

// Background colors
$color-bg__dark: #012124;
$color-bg__light: #d0d1d1;

// Spaces
$space__base: 8px;

// Font sizes
$font-size__base: 18px;

// CSS grid span
$grid-span__small: span 1;
$grid-span__big: span 2;

// Transition
$transition-duration: 0.5s;
$transition-delay: 3s;
$transition-timing-function: linear;
$transition__btn-direction: all 1s ease-in-out;

// Z-Indices
$z-index: (
  btn-circle: 400,
  blood-count: 300,
  quicklinks: 200,
  header-home: 200,
  btn-direction: 200,
  btn-direction-text: 199,
  btn-direction-line: 198,
  navigation: 100,
  post-content: 100,
  background-layer: 50,
);

:root {
  --font-size__content: 18;
  --luke-fill: 100;
  --luke-line: 100;
  --clouds: 0.5;
  --color__blood: #{$color__blood};
}

[data-theme="light"] {
  --color__bg: #{$color-bg__light};
  --color: #{$color__dark};

  --color__blood-2: #{$mixed-color__blood-dark};
  --color__blood-3: #{$mixed-color__blood-darker};
}

[data-theme="dark"] {
  --color__bg: #{$color-bg__dark};
  --color: #{$color__light};

  --color__blood-2: #{$mixed-color__blood-light};
  --color__blood-3: #{$mixed-color__blood-lighter};
}
