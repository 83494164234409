// -------------------------------------------------------------
// Mixins & functions
// -------------------------------------------------------------

// Z-index map function
@function z($key) {
  @return map-get($z-index, $key);
}

// Button reset
@mixin button-reset {
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
  font: inherit;
  color: inherit;
  background: none;
  text-decoration: none;

  &:focus {
    outline: none;
  }
}

// Time format
@mixin time-format {
  > span:nth-child(1)::after {
    content: "A ";
  }
  > span:nth-child(2)::after {
    content: "M ";
  }
  > span:nth-child(3)::after {
    content: "D";
  }
}

// Font Luke
@mixin font-family-luke {
  font-family: "LukeVariableFont";
  font-variation-settings: "fill" var(--luke-fill), "line" var(--luke-line);
  font-weight: 400;
}

// Font Neco
@mixin font-family-neco {
  font-family: "Neco";
}

// Fade in from none keyframes
@keyframes fadeInFromNone {
  0% {
    opacity: 0;
  }

  1% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

// Rotate back keyframes
@keyframes rotateBack {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0);
  }
}

// Rotate forward keyframes
@keyframes rotateForward {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
